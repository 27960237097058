import React from 'react';
import LogoBanorte from '../img/logo-banorte.png';
import { useAuth } from '../App';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { setIsAuthenticated } = useAuth(); 
  const navigate = useNavigate();

  const handleLogout = () => {
    setIsAuthenticated(false); 
    navigate('/login'); 
  };

  return (
    <header className="flex justify-between items-center p-5 bg-gray-800">
      <div className="w-[200px] flex justify-center items-center overflow-hidden">
        <img src={LogoBanorte} alt="banorte Logo" className="max-w-full max-h-full" />
      </div>
      <button
        onClick={handleLogout}
        className="text-white hover:underline"
      >
        Cerrar sesión
      </button>
    </header>
  );
};

export default Header;
