import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDocuments } from '../api/documents';
import { FaFolder } from 'react-icons/fa';

const Folders = () => {
  const navigate = useNavigate();
  const [folders, setFolders] = useState([]); // Carpetas originales
  const [searchTerm, setSearchTerm] = useState(''); // Término de búsqueda
  const [filteredFolders, setFilteredFolders] = useState([]); // Carpetas filtradas
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFolders = async () => {
      try {
        const data = await fetchDocuments();
        const uniqueConvos = [...new Set(data.map((doc) => doc.Convo))];
        setFolders(uniqueConvos);
        setFilteredFolders(uniqueConvos); // Inicialmente, todas las carpetas están visibles
      } catch (error) {
        console.error('Error al cargar las carpetas:', error);
      } finally {
        setLoading(false);
      }
    };

    loadFolders();
  }, []);

  // Manejar el término de búsqueda
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    // Filtrar carpetas según el término de búsqueda
    const filtered = folders.filter((convo) => convo.toLowerCase().includes(term));
    setFilteredFolders(filtered);
  };

  if (loading) {
    return <div className="text-center">Cargando carpetas...</div>;
  }

  return (
    <div className="p-4">
      {/* Buscador */}
      <div className="mb-4 flex justify-start">
        <input
          type="text"
          placeholder="Buscar por número de usuario..."
          value={searchTerm}
          onChange={handleSearch}
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-banorte-red text-black"
          style={{ maxWidth: '400px', width: '100%' }} 
        />
      </div>

      {/* Carpetas */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        {filteredFolders.map((convo) => (
          <div
            key={convo}
            className="flex flex-col items-center justify-center cursor-pointer text-banorte-red hover:text-red-800 transition-colors"
            onClick={() => navigate(`/folder/${convo}`)}
          >
            <FaFolder size={100} className="mb-2 text-banorte-red hover:text-red-800 transition-colors" />
            <p className="text-center font-bold">Usuario: {convo}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Folders;
