import React, { useState, createContext, useContext } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Folders from './components/Folders';
import FolderDetails from './components/FolderDetails';
import Login from './components/Login';
import DocumentView from './components/DocumentView';



// Contexto para autenticación
const AuthContext = createContext();

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado para autenticación

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/"
          element={
            isAuthenticated ? (
              <ProtectedLayout>
                <main className="flex-1 flex items-center justify-center px-5 bg-gray-900">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-5xl h-[80vh] overflow-y-auto flex flex-col mb-6">
                    <NavigationTabs /> 
                    <Folders numberOfFolders={10} />
                  </div>
                </main>
              </ProtectedLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/folder/:folderId"
          element={
            isAuthenticated ? (
              <ProtectedLayout>
                <main className="flex-1 flex items-center justify-center px-5 bg-gray-900">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-5xl h-[80vh] overflow-y-auto flex flex-col mb-6">
                    <FolderDetails />
                  </div>
                </main>
              </ProtectedLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/reportes"
          element={
            isAuthenticated ? (
              <ProtectedLayout>
                <main className="flex-1 flex items-center justify-center px-5 bg-gray-900">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-5xl h-[80vh] overflow-y-auto flex flex-col mb-6">
                    <NavigationTabs /> {/* Agregamos las pestañas */}
                  </div>
                </main>
              </ProtectedLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
  path="/document/:documentId"
  element={
    isAuthenticated ? (
      <ProtectedLayout>
        <main className="flex-1 flex items-center justify-center px-5 bg-gray-900">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-5xl h-[80vh] overflow-y-auto flex flex-col mb-6">
            <DocumentView />
          </div>
        </main>
      </ProtectedLayout>
    ) : (
      <Navigate to="/login" replace />
    )
  }
/>

        {/* Ruta por defecto */}
        <Route path="*" element={<Navigate to={isAuthenticated ? "/" : "/login"} replace />} />
      </Routes>
    </AuthContext.Provider>
  );
};

// Componente para las pestañas de navegación
const NavigationTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex mb-4 space-x-4">
      <button
        onClick={() => navigate('/')}
        className={`text-lg font-bold ${
          location.pathname === '/' ? 'text-banorte-red' : 'text-gray-400'
        }`}
      >
        Documentos
      </button>
    </div>
  );
};

// Componente para el layout protegido con encabezado y diseño general
const ProtectedLayout = ({ children }) => (
  <div className="flex flex-col min-h-screen bg-gray-900 text-white">
    <Header /> {/* Mostrar el header en todas las vistas protegidas */}
    {children} {/* Contenido de las rutas protegidas */}
  </div>
);

export default App;

// Hook personalizado para usar el contexto de autenticación
export const useAuth = () => useContext(AuthContext);
