import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from '../App'; // Contexto de autenticación
import back from '../img/banorte-login.jpeg';
import logo from '../img/Banorte-logo.png';

const Login = () => {
  const { setIsAuthenticated } = useAuth(); // Estado global de autenticación
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Credenciales falsas
  const fakeCredentials = {
    username: 'admin',
    password: 'password123',
  };

  // Manejo del formulario de inicio de sesión
  const handleSubmit = (e) => {
    e.preventDefault();

    if (username === fakeCredentials.username && password === fakeCredentials.password) {
      Swal.fire({
        title: 'Iniciando sesión...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      setTimeout(() => {
        Swal.close();
        setIsAuthenticated(true); // Actualizar estado de autenticación
        navigate('/'); // Redirigir a la página principal
      }, 2000);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Credenciales incorrectas',
        text: 'El usuario o la contraseña son incorrectos, por favor intenta nuevamente',
      });
    }
  };

  return (
    <div
      className="min-h-screen flex mb-10"
      style={{
        backgroundImage: `url(${back})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Contenedor del formulario */}
      <div className="flex-1 flex items-center justify-center">
      <div className="relative z-10 bg-white p-10 rounded-lg shadow-lg w-11/12 mr-auto ml-10 h-[95vh]">
          <div className="text-center mb-6">
          <img src={logo} alt="SaptibankLogo" className="mx-auto w-80 mb-1 mt-10" />
            <h2 className="text-4xl font-bold text-banorte-red mt-10">Ingreso</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-lg mt-20 font-bold"
                htmlFor="username"
              >
                Usuario
              </label>
              <input
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-banorte-red"
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Ingresa tu usuario"
              />
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 text-lg mt-5 font-bold"
                htmlFor="password"
              >
                Contraseña
              </label>
              <input
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-banorte-red"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Ingresa tu contraseña"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-banorte-red text-white py-2 rounded-lg hover:bg-red-700 transition duration-300 font-bold mt-15"
            >
              Iniciar sesión
            </button>
          </form>
        </div>
      </div>

      <div className="hidden lg:flex flex-1 items-center justify-center">
      </div>
    </div>
  );
};

export default Login;
