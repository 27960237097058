import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { fetchDocuments, fetchDocumentExtraction } from '../api/documents';
import { AiOutlineFileSearch, AiOutlineArrowLeft } from 'react-icons/ai';
import { MdInsights } from "react-icons/md";

const FolderDetails = () => {
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const data = await fetchDocuments(folderId);
        setDocuments(data || []);
      } catch (error) {
        console.error('Error al cargar los documentos:', error);
        alert('Error al cargar los documentos. Intenta nuevamente.');
      } finally {
        setLoading(false);
      }
    };

    loadDocuments();
  }, [folderId]);

  const handleViewDocument = async (id) => {
    try {
      const extractions = await fetchDocumentExtraction();

      const documentData = extractions.find((item) => item.document_id === id);
      if (!documentData) {
        Swal.fire({
          icon: 'error',
          title: 'Documento no encontrado',
          text: `No se encontró un documento con el ID ${id}.`,
        });
        return;
      }

      const resultadoString = documentData.resultado.replace(/'/g, '"');
      const resultadoJSON = JSON.parse(resultadoString);

      console.log('Resultado procesado:', resultadoJSON);

      localStorage.setItem(`resultado_document_${id}`, JSON.stringify(resultadoJSON));
      navigate(`/document/${id}`);
    } catch (error) {
      console.error('Error al procesar el documento:', error);
    }
  };

  const handleAnalyzeIncome = (id) => {
    // Aquí puedes implementar la lógica para el análisis de ingresos.
    console.log(`Analizando ingresos para el documento con ID ${id}`);
    Swal.fire({
      icon: 'info',
      title: 'Análisis de Ingresos',
      text: `Análisis de ingresos para el documento con ID ${id}.`,
    });
  };

  const filteredDocuments = documents.filter((doc) => doc.Convo === folderId);

  if (loading) {
    return <div className="text-center">Cargando documentos...</div>;
  }

  return (
    <div className="w-full h-full overflow-y-auto p-4">
      <button
        onClick={() => navigate(-1)}
        className="text-banorte-red hover:text-banorte-red transition flex items-center mb-4"
      >
        <AiOutlineArrowLeft size={24} className="mr-2" />
        <span className="text-sm font-bold">Volver</span>
      </button>

      <h1 className="text-2xl font-bold text-center text-banorte-red mb-6">
        Usuario: {folderId}
      </h1>

      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2 bg-gray-100 text-gray-500">
              Tipo de documento
            </th>
            <th className="border border-gray-300 p-2 bg-gray-100 text-gray-500">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredDocuments.map((doc) => (
            <tr key={doc.ID}>
              <td className="border border-gray-300 p-2 text-center text-gray-400 font-bold">
                {doc.Type}
              </td>
              <td className="border border-gray-300 p-2 text-center">
                <button
                  className="text-banorte-red hover:text-banorte-red transition mr-2"
                  onClick={() => handleViewDocument(doc.ID)}
                >
                  <AiOutlineFileSearch size={24} />
                </button>
                {doc.Type === 'Comprobante de Ingresos' && (
                  <button
                    className="text-green-500 hover:text-green-700 transition"
                    onClick={() => handleAnalyzeIncome(doc.ID)}
                  >
                  <MdInsights size={24} />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FolderDetails;
