// documentos metodo GET
export const fetchDocuments = async () => {
    try {
      const response = await fetch('https://saptivabot.vulcanics.mx/api/documents', {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error(`Error al obtener los documentos: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error al consumir la API de documentos:', error);
      throw error;
    }
  };


  export const fetchDocumentExtraction = async () => {
    try {
      const response = await fetch('https://saptivabot.vulcanics.mx/api/extracciones');
      if (!response.ok) {
        throw new Error(`Error al obtener extracciones: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error al consumir la API de extracciones:', error);
      throw error;
    }
  };
  