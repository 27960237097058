import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const DocumentView = () => {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [documentURL, setDocumentURL] = useState(null);
  const [documentInfo, setDocumentInfo] = useState(null);
  const [classification, setClassification] = useState('');

  useEffect(() => {
    const storedDocument = localStorage.getItem(`resultado_document_${documentId}`);
    if (storedDocument) {
      const parsedDocument = JSON.parse(storedDocument);

      setClassification(parsedDocument.CLASIFICACION || '');

      const filteredDocument = Object.entries(parsedDocument).reduce((acc, [key, value]) => {
        if (
          (parsedDocument.CLASIFICACION === 'EECC_BBVA_ELECTRO' && (key === 'WorkItemId' || key === 'CLASIFICACION')) ||
          (parsedDocument.CLASIFICACION === 'COMPROBANTE_CFE' && key.startsWith('CFE')) ||
          (parsedDocument.CLASIFICACION === 'SAT' && key.startsWith('CSF')) ||
          (!key.startsWith('CSF') &&
            !key.startsWith('CFE') &&
            parsedDocument.CLASIFICACION !== 'COMPROBANTE_CFE' &&
            parsedDocument.CLASIFICACION !== 'SAT' &&
            parsedDocument.CLASIFICACION !== 'EECC_BBVA_ELECTRO') // Mostrar los demás datos si no es CFE, CSF o EECC_BBVA_ELECTRO
        ) {
          acc[key] = value;
        }
        return acc;
      }, {});

      setDocumentInfo(filteredDocument);
    } else {
      alert('No se encontró información del documento.');
      navigate(-1); // Regresar si no hay datos
    }

    // Cargar el PDF si aplica
    const fetchDocumentDetails = async () => {
      try {
        const response = await fetch(`https://saptivabot.vulcanics.mx/api/document?id=${documentId}`);
        const contentType = response.headers.get('Content-Type');

        if (contentType === 'application/pdf') {
          const blob = await response.blob();
          const pdfURL = URL.createObjectURL(blob);
          setDocumentURL(pdfURL);
        } else {
          console.error('Tipo de contenido no esperado:', contentType);
        }
      } catch (error) {
        console.error('Error al obtener los detalles del documento:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentDetails();
  }, [documentId, navigate]);

  if (loading) {
    return <div className="text-center text-black">Cargando documento...</div>;
  }

  if (!documentURL) {
    return <div className="text-center text-red-500">No se encontró el documento o no es un PDF válido.</div>;
  }

  if (!documentInfo) {
    return <div className="text-center text-red-500">No se encontraron detalles del documento.</div>;
  }

  return (
    <div className="flex flex-col h-full text-black">
      <div>
        <button
          onClick={() => navigate(-1)} // Regresa a la página anterior
          className="text-banorte-red hover:text-red-700 transition duration-200 flex items-center mb-4 px-4"
        >
          <AiOutlineArrowLeft size={24} className="mr-2" />
          <span className="text-sm font-bold">Volver</span>
        </button>
      </div>

      {/* Contenido principal */}
      <div className="flex flex-col md:flex-row gap-6 h-full">
        <div className="w-full md:w-2/3 h-full">
          <iframe
            src={documentURL}
            title="Documento PDF"
            className="w-full h-full border-0"
          ></iframe>
        </div>

        {/* Columna derecha: Información detallada */}
        <div className="w-full md:w-1/3 bg-gray-100 p-4 rounded-lg overflow-y-auto h-full">
          <h2 className="text-xl font-bold mb-4">Detalles del Documento</h2>
          <div className="mb-4">
            <span className="font-bold">Clasificación:</span> {classification}
          </div>

          {/* Renderizar detalles del documento */}
          <div className="space-y-2">
            {Object.entries(documentInfo).map(([key, value]) => {
              const displayKey = key === 'WorkItemId' ? 'Identificador' : key; // Cambiar el nombre de WorkItemId
              return (
                <div className="flex" key={key}>
                  <span className="font-bold text-gray-600">{displayKey}:</span>
                  <span className="ml-2">{value || 'N/A'}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentView;
